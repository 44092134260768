.card{
    width: 100%;
    margin: 24px 0;
 
      /* border-bottom: 1px solid rgb(196, 194, 194);  */
      
    &:last-child{
        border-bottom: none;
    
    } 
      &:first-child{
     
        border-top: none;
      
    }
  
}
.heading{
    color: black;
    font-size: 20px;
    font-weight: 700;
}
 
.headingBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
       &>h3{
        margin: 0 !important;
        max-width:90%;
    }
    @media(max-width:767px){
        & > h3{
            /* max-width:480px; */
            line-height: 30px;
        }
    }
    padding: 10px 30px;
    background-color: rgb(245 245 245);
     border: 1px solid  rgb(245 245 245);
    /* background-color: rgb(239 237 237); */
    /* background-color: white; */
    border-radius: 8px ;
    /* background-color: #faf6ed; */
   
/* padding-right:  20px; */
}
.headingBoxExpand{
    border-radius: 8px 8px 0 0;
}
.icon {
    width: 14px !important;
    height: 14px !important;
}
.bulletlist {
/* padding: 0 20px; */
padding-left: 20px;
&>li{
  list-style-type: disc;
}
 /* Specifies the type of bullet point */
  /* You can use other values like circle or square */
}
.para{
    /* padding: 0 15px; */
    padding: 10px 30px;

    width: 100%;
    /* border: 1px solid  rgb(245 245 245); */
    border: 1px solid  rgb(239 237 237);
    border-top: none;
    border-radius: 8px;
    /* background-color: white !important; */
}
.paraExpand{
       border-radius:0 0 8px 8px;
}
.boxContainer{
    border-bottom: none;
    width: 100%;
    height: auto;
     /* border: 1px solid rgb(239 237 237) ;  */
     
         /* border-radius: 10px;  */
      /* background-color: rgb(239 237 237); */
        

   
   
}