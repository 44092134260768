.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.previewVideo{
   position: relative;

  width: 100%;
 object-fit: cover;
/* height: auto; */
  height: 100%;
}
.desc{
 position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
align-items: center;
color: white;
margin-top: 50px;
&>header{
  & h1{
    color: white;
  }
}
}
.wrapper{
   
  
  height: 100vh;

}